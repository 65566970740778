module controllers {
    export module tariff {
        export class tariffBrowseCtrl {

            static $inject = [
                '$scope',
                '$rootScope',
                '$q',
                '$uibModal',
                'entityService',
                'bsLoadingOverlayService',
                'tariffCountryService',
                'classificationValueService',
                'hsCodeService',
                '$timeout',
                'menuService',
                '$state',
                'tariffBookService',
                'wcoTradeToolsService'
            ];
            searchAccordian: boolean = false;

            selectedTariffId: number;
            tariffTypes: interfaces.applicationcore.IDropdownModel[];

            selectedCountry: interfaces.applicationcore.IDropdownModel;
            simulationDate: moment.Moment = moment.utc();
            nationalMeasureCode: string;
            description: string;
            exclusions: boolean = true;

            messages: interfaces.applicationcore.IMessage[];

            tariffBook: interfaces.tariffbook.ITariffBookSection[];

            apiTariffCodes: uiGrid.IGridApi;

            showGrid: boolean = false;
            isLoading: boolean = false;

            tariffDetail: interfaces.tariff.INationalMeasureCodeDetail;

            showAll: boolean = false;
            selectedCode: string = "";
            
            wcoTradeToolsEnabled: boolean = false;

            constructor(
                private $scope: ng.IScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private entityService: interfaces.applicationcore.IEntityService,
                private bsLoadingOverlayService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                public $timeout: ng.ITimeoutService,
                private menuService: interfaces.applicationcore.IMenuService,
                private $state: ng.ui.IStateService,
                private tariffBookService: interfaces.tariffbook.ITariffBookService,
                private wcoTradeToolsService: interfaces.tariff.IWCOTradeToolsService
            ) {
                this.selectedTariffId = Enum.EnumTariffCodeType.NationMeasure;
                this.nationalMeasureCode = "";

                var promises: ng.IPromise<any>[] = [];

                var countryPromise = entityService.getEntityCountry().get({ entityId: 0 }, (result: interfaces.applicationcore.ICountry) => {
                    this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Id,
                        Code: result.Code,
                        Description: result.Description,
                        Display: result.Code + " - " + result.Description,
                        Selected: true
                    };
                }).$promise;

                
                var verifyWCOTradeToolsEnabled = wcoTradeToolsService.VerifyWCOTradeToolsEnabled().get({ }, (result: interfaces.tariff.VerifyTradeTools) => {

                    this.wcoTradeToolsEnabled = result.isWCOTradeToolsEnabled;
                }).$promise;            

                promises.push(verifyWCOTradeToolsEnabled);

                promises.push(countryPromise);

                promises.push(this.loadTariffCodeTypes());             

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.browse'
                }, () => {
                    return $q.all(promises).then(() => { this.loadTariffBook(); })
                });
            }

            search_click() {
                this.searchAccordian = false;
                this.isLoading = true;
                this.showGrid = true;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.browse'
                }, () => {
                    return this.loadTariffBook();
                });
            }

            loadTariffCodeTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.TariffCodeType
                }, (resultList) => {
                    this.tariffTypes = resultList;
                }).$promise;
            }

            loadCountries() {
                return this.tariffCountryService.getDropdownList().query().$promise;
            }

            loadTariffBook() {
                this.searchAccordian = false;
                this.isLoading = true;

                return this.tariffBookService.getSections().query({
                    type: this.selectedTariffId,
                    countryId: this.selectedCountry.Id,
                    simulationDate: this.simulationDate.format("YYYYMMDDTHH:mm"),
                    exclusions: this.exclusions,
                    dutyTypeId: 0
                }, (resultList) => {

                    this.tariffBook = resultList;
                    this.isLoading = false;
                }, (errorResponse) => {
                    this.isLoading = false;
                    if (errorResponse.data.Messages) {
                        this.messages = [];
                    }

                    angular.forEach(errorResponse.data.Messages, (o) => {
                        angular.forEach(o.MessageItems, (n) => {
                            this.messages.push(n);
                        });
                    });
                }).$promise;
            }

            showTariffBookNotes(dutyTypeId: number, countryId: number, simulationDate: moment.Moment, sectionNumber: string) {
                return this.tariffBookService.getTariffBookNotes().query({
                    type: dutyTypeId,
                    countryId: countryId,
                    simulationDate: simulationDate.format("YYYYMMDDTHH:mm"),
                    code: sectionNumber,
                    isSection: true
                }, (data) => {
                    return this.displayNotes(data.Notes);
                }, (errorResponse) => {
                    this.isLoading = false;
                    if (errorResponse.data.Messages) {
                        this.messages = [];
                    }

                    angular.forEach(errorResponse.data.Messages, (o) => {
                        angular.forEach(o.MessageItems, (n) => {
                            this.messages.push(n);
                        });
                    });
                }).$promise;
            }

            displayNotes(notes: string) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Notes</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Notes"></p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = notes;

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            typeChange() {
                _.forEach(this.tariffBook, (section) => {
                    section.IsExpanded = false;
                });
                this.search_click();
            }

        };

        angular.module("app").controller("tariffBrowseCtrl", controllers.tariff.tariffBrowseCtrl);
    }
}